import React, { useRef, useState } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import UploadImageContent from '@/components/HairChangeContent/UploadImageContent';
import { useUserProvider } from '@/context/UserProvider';
import { imgUrl } from '@/shared/utils';
import Image from 'next/image';

const TopInfluencersFollowContent: React.FC<any> = ({ upsList }) => {
  const { t } = useTranslation();
  const router = useRouter();
  const containerRef = useRef(null);
  const [innnerUpList, setInnerUpList] = useState(upsList);
  const { isLogin, onOpenLoginModal } = useUserProvider();

  const [libId, setLibId] = useState<any>(null);

  // 获取更多数据
  const handleGetMore = async () => {
    router.push(`/influencers?type=1`);
  };

  return (
    <div className={styles.content}>
      <div>
        <span>{t('Top Hair Influencers to Follow')}</span>
      </div>
      <p className={styles.desc}>
        {t(
          'Follow Your Favorite Hairstyle Influencer. Be the first to try on the latest hairstyles as soon as your favorite influencers update their styles.',
        )}
      </p>

      <div className={styles.container} ref={containerRef}>
        {innnerUpList.map((item: any, index: number) => (
          <div
            key={index}
            className={classNames(styles.topInfoCon)}
            style={{
              backgroundImage: `url('/images/hairstyle/index-top-follow-${index % 4}.png')`,
            }}
            onClick={() => {
              router.push(`/influencers/${item?.nickname}`);
            }}
          >
            <div className={styles.leftInfo}>
              <div>
                <Image
                  src={
                    item.avatar ||
                    'https://res-ins-test.pumpsoul.com/ins_image/17168795945613202686.jpg'
                  }
                  alt=""
                  width={55}
                  height={55}
                  className={styles.img}
                  loading="lazy"
                />
              </div>
              <div>{item.nickname}</div>
              <div>{`${item.follower_num} followers`}</div>
              <div>{`${item.hairstyle_num} hairstyles`}</div>

              <div
                className={styles.moreContainer}
                onClick={(e) => {
                  e.stopPropagation();
                  router.push(`/influencers/${item?.nickname}`);
                }}
              >
                {t('More')}
              </div>
            </div>

            <div className={styles.spaceDiv} />

            <div className={styles.rightImgs}>
              {item.images.slice(0, 4).map((i_lab: any, i_index: number) => (
                <div key={i_index}>
                  <Image
                    src={imgUrl(i_lab?.image_url, 'mid')}
                    alt={'hairpaca hairstyle'}
                    width={183}
                    height={305}
                    className={styles.img}
                    loading="lazy"
                  />
                  <div
                    className={styles.btn}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isLogin) {
                        setLibId(i_lab?.lib_id);
                      } else {
                        onOpenLoginModal(true);
                      }
                    }}
                  >
                    {t('Try On')}
                  </div>
                </div>
              ))}
            </div>

            <div
              className={styles.moreContainer}
              onClick={(e) => {
                e.stopPropagation();
                router.push(`/influencers/${item?.nickname}`);
              }}
            >
              {t('More')}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.bottomDesc} onClick={handleGetMore}>
        {t('Discover more hair influencers')}
        <Image
          src={'/images/hairstyle/arrow_icon_1.png'}
          alt={''}
          width={12}
          height={12}
          style={{ width: '12px', height: '12px' }}
          loading="lazy"
        />
      </div>

      <UploadImageContent openId={libId} handleClose={() => setLibId(null)} />
    </div>
  );
};

export default TopInfluencersFollowContent;
